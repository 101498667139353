import { graphql, PageProps } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"

import * as headingStyles from "../css/heading.module.css"
import * as proseStyles from "../css/prose.module.css"

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface ArticleProps extends PageProps {
	data: {
		site: {
			siteMetadata: {
				siteName: string
			}
		}
	}
}

export const query = graphql`
	query ArticleBySlug($slug: String!) {
		contentfulArticle(slug: {eq: $slug}, content: {}) {
			title
			articlePublishedOn(formatString: "MMMM Do, YYYY")
			content {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`

export default class ArticlePage extends React.Component<ArticleProps> {
	public render() {
		const { title, content, articlePublishedOn } = this.props.data.contentfulArticle
		
		return (
			<Layout>
				<div className={headingStyles.container}>
					<h1 className={headingStyles.h1}><strong>{title}</strong></h1>
					<div className={headingStyles.date}>{articlePublishedOn}</div>
				</div>
				
				<div
					className={proseStyles.prose}
					dangerouslySetInnerHTML={{
						__html: content.childMarkdownRemark.html,
					}}
				/>
			</Layout>
		)
	}
}
